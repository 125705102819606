@use "@raspberrypifoundation/design-system-core/scss/mixins/typography";
@use "./mixins" as *;

.unable-to-access {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  block-size: 100dvh;
  margin-inline: var(--space-4);
  margin-block: var(--space-6);
  gap: var(--space-6);

  &__container {
    background-color: var(--rpf-white);
    padding: var(--space-4);
    border-radius: var(--space-1);
    text-align: start;
    max-inline-size: 600px;
    inline-size: 100%;

    &-title {
      @include typography.style-2(bold);
      margin: 0;
    }
    &-description {
      p {
        margin-block-end: 0;
      }
      ul {
        margin-block-start: 0;
        padding-inline-start: var(--space-3);
      }
    }
  }
  &__links {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    justify-content: flex-end;

    &-buttons {
      display: flex;
      gap: var(--space-2);
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
